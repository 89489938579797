/* Switch for the Commissioning API. If set to _Commissions, ensure the 
 * manual-commission.js Reactor script is deployed to emulate.
 */
const COMMISSION_ACTION_TYPE = 'commissions';

export const LS_USER_KEY = 'ad-user-key';
export const LS_USER_ID = 'ad-user-id';

/** Event type action types and friendly labels */
export const ActionTypes = [
  { label: 'Select event type...', value: '' },
  { label: 'Raw Material Produced', value: '_RawMaterialsProduced' },
  { label: 'Raw Material Shipped', value: '_RawMaterialsShipped' },
  { label: 'Raw Material Received', value: '_RawMaterialsReceived' },
  { label: 'Labels Produced', value: '_LabelsProduced' },
  { label: 'Label Applied', value: COMMISSION_ACTION_TYPE },
  { label: 'Finished Goods Shipped', value: '_FinishedGoodsShipped' },
  { label: 'Finished Goods Received', value: '_FinishedGoodsReceived' },
  { label: 'Finished Goods Sold', value: '_FinishedGoodsSold' },
];

/** Show fields depending on the selected type. */
export const ActionCustomFields = {
  '': [],
  _RawMaterialsProduced: [
    'itemName',
    'materialType',
    'productionDate',
    'compositionDetails',
    'dimensions',
    'quantity',
  ],
  _RawMaterialsShipped: [
    'itemName',
    'materialType',
    'productionDate',
    'compositionDetails',
    'dimensions',
    'quantity',
    'Raw Material PO Number',
    'trackingNumber',
    'shippingDate',
    'shippingWeight',
    'destination',
  ],
  _RawMaterialsReceived: [
    'itemName',
    'quantity',
    'materialType',
    'receivingDate',
    'Raw Material PO Number',
    'dimensions',
  ],
  _LabelsProduced: [
    'itemName',
    'Customer PO Number',
    'inkBatchNumber',
    'tapeBatchNumber',
    'productionDate',
    'inkRawMaterialUsed',
    'tapeRawMaterialUsed',
  ],
  [COMMISSION_ACTION_TYPE]: [  // AKA Label Applied in the UI
    'Customer PO Number',
    'productionDate',
    'product',
  ],
  _FinishedGoodsShipped: [
    'quantity',
    'trackingNumber',
    'shippingDate',
    'shippingWeight',
    'destination',
    'product',
  ],
  _FinishedGoodsReceived: [
    'quantity',
    'receivingDate',
    'product',
  ],
  _FinishedGoodsSold: [],
};

/** Show different identification methods depending on type */
export const ActionIdentifyMethods = {
  '': 'none',
  _RawMaterialsProduced: 'batch',
  _RawMaterialsShipped: 'batch',
  _RawMaterialsReceived: 'batch',
  _LabelsProduced: 'scan',
  [COMMISSION_ACTION_TYPE]: 'scan',
  _FinishedGoodsShipped: 'po',
  _FinishedGoodsReceived: 'po',
  _FinishedGoodsSold: 'scan',
};

/** Show different event types depending on location. */
export const PlaceActionTypes = {
  // Not selected
  '': [],
  // Italy
  UqfwY2FfBbtHErRwRmEP6aMk: [
    '_RawMaterialsProduced',
    '_RawMaterialsShipped',
  ],
  // Spain
  U6fah2Ea4gA7egwawGmyBnPq: [
    '_RawMaterialsReceived',
    '_LabelsProduced',
    COMMISSION_ACTION_TYPE, 
    '_FinishedGoodsShipped',
  ],
  // Norway
  U6CwE2fg59CbRAaawGdNeaKb: [
    '_FinishedGoodsReceived',
    '_FinishedGoodsSold',
  ],
};

/**
 * Return the right units for each kind of event data field.
 *
 * @param {string} fieldName - The name of the field as a custom field.
 * @returns {string} The unit string to use. 
 */
export const getFieldUnits = (fieldName) => {
  const map = {
    dimensions: 'M x mm',
    quantity: 'units',
    shippingWeight: 'Kg',
  };

  return map[fieldName] || '';
};

/**
 * Map place resources to the { label, value } format expected by the shared component.
 *
 * @param {Array} places - The places to map.
 * @returns {Array} List of transformed places.
 */
export const convertPlacesForSelect = places =>
  [{ label: 'Select a location...', value: '' }]
    .concat(places.map(p => ({ label: p.name, value: p.id })));

/**
 * Show only some event types for each location.
 * Also include the empty string one so that a choice is forced (updates state)
 *
 * @param {object} state - The app state.
 * @returns {Array} Array of filtered event types for this location.
 */
export const filterEventTypes = state => 
  ActionTypes.filter(p => PlaceActionTypes[state.place || ''].includes(p.value) || p.value === '');

/**
 * Asynchronous wait function that works with Promises.
 *
 * @param {number} ms - Number of milliseconds to wait.
 */
const waitAsync = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Run a batch of tasks (functions that return async functions) in a queue.
 *
 * @param {Array} queue - Queue of tasks.
 * @param {number} numInParallel - Number to do in a group.
 * @param {number} waitMs - Time to wait between groups.
 */
export const runTaskBatch = (queue, numInParallel = 1, waitMs = 100) => {
  if (!queue || !queue.length) {
    return Promise.resolve();
  }

  const batch = queue.splice(0, numInParallel);
  return Promise.all(batch.map(item => item()))
    .then(() => waitAsync(waitMs))
    .then(() => runTaskBatch(queue, numInParallel, waitMs));
};
