import React from 'react';
import {
  ActionIdentifyMethods, ActionCustomFields, convertPlacesForSelect, filterEventTypes,
} from '../util';
import { Fonts, whiteButtonStyle, formatCamelCase } from '../../../consumer-web-app/src/util';
import { DATA_ROW_CLASS, Select } from '../components/FormComponents';
import BatchNumberPage from './BatchNumberPage';
import Button from '../../../consumer-web-app/src/components/Button';
import EventDataTable from '../components/EventDataTable';
import Fader from '../../../consumer-web-app/src/components/Fader';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import PONumberPage from '../pages/PONumberPage';
import ScanThngPage from '../../../consumer-web-app/src/pages/ScanThngPage';
import Subtitle from '../../../consumer-web-app/src/components/Subtitle';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

const DEFAULT_INK = 'HS1111-3.5-500P9';
const DEFAULT_TAPE = '4921THS-3.0-250C2';

/**
 * Determine if every action data item has a value.
 *
 * @returns {Promise} Promise that resolves if every element with className
 *   DATA_ROW_CLASS is not emptystring.
 */
const checkEventDataEntered = () => {
  // 'found' can't be iterated...
  const found = document.getElementsByClassName(DATA_ROW_CLASS);
  const elements = [];
  for (let i = 0; i < found.length; i += 1) {
    elements.push(found[i]);
  }

  const formFilled = elements.every(p => p.value !== '');
  if (!formFilled) {
    alert('Please complete the entire form.');
    return Promise.reject();
  }

  return Promise.resolve();
};

const DataEntryPage = ({ state, setState, appCallbacks }) => {
  const entryMethod = ActionIdentifyMethods[state.actionType];

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/calendar-edit.png"/>
        <Title>Enter a Traceability Event</Title>
        <Paragraph>
          Select an event type, your facility name, then enter the details (scan QR code if 
          applicable).
        </Paragraph>

        <Subtitle>Event Location</Subtitle>
        <Select items={convertPlacesForSelect(state.places)} value={state.place}
          onChange={el => setState({
            place: el.target.value,
            actionType: '', 
            actionFields: {},
          })}/>

        {state.place !== '' && (
          <Fader>
            <Subtitle>Event Type</Subtitle>
            <Select items={filterEventTypes(state)} value={state.actionType}
              onChange={(el) => {
                // New event, clear all event data
                const actionFields = {};

                // Prefill some fields here
                if (el.target.value === '_LabelsProduced') {
                  actionFields.inkRawMaterialUsed = DEFAULT_INK;
                  actionFields.tapeRawMaterialUsed = DEFAULT_TAPE;
                }

                setState({ actionType: el.target.value, actionFields });
              }}/>

            {(state.actionType !== '' && ActionCustomFields[state.actionType].length !== 0) && (
              <Fader>
                <Subtitle>Event Data</Subtitle>
                <EventDataTable state={state} setState={setState} appCallbacks={appCallbacks}
                  actionType={state.actionType}/>
              </Fader>
            )}
            
            {entryMethod !== 'none' && (
              <Fader>
                <Subtitle>Identify Item</Subtitle>
              </Fader>
            )}
            {entryMethod === 'scan' && (
              <Fader>
                <Button onClick={() => {
                  checkEventDataEntered().then(() => setState({ currentPage: ScanThngPage }));
                }}>
                  Scan QR Code
                </Button>
              </Fader>
            )}
            {entryMethod === 'batch' && (
              <Fader>
                <Button onClick={() => {
                  checkEventDataEntered().then(() => setState({ currentPage: BatchNumberPage }));
                }}>
                  Enter Batch Number
                </Button>
              </Fader>
            )}
            {entryMethod === 'po' && (
              <Fader>
                <Button onClick={() => {
                  checkEventDataEntered().then(() => setState({ currentPage: PONumberPage }));
                }}>
                  Enter PO Number
                </Button>
              </Fader>
            )}
          </Fader>
        )}
      </WideContainer>
    </Fader>
  );
};

export default DataEntryPage;
