import React from 'react';
import { ActionTypes, convertPlacesForSelect } from '../util';
import { whiteButtonStyle } from '../../../consumer-web-app/src/util';
import Button from '../../../consumer-web-app/src/components/Button';
import Fader from '../../../consumer-web-app/src/components/Fader';
import CustomFieldsTable from '../../../consumer-web-app/src/components/CustomFieldsTable';
import DataEntryPage from './DataEntryPage';
import ScanThngPage from '../../../consumer-web-app/src/pages/ScanThngPage';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import ProductImage from '../../../consumer-web-app/src/components/ProductImage';
import Subtitle from '../../../consumer-web-app/src/components/Subtitle';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

/**
 * Determine whether the SKU can/should be shown.
 *
 * @param {object} state - The app state.
 * @returns {boolean} true if the SKU should be shown.
 */
const showSKU = (state) => {
  const shown = ['_FinishedGoodsShipped', '_FinishedGoodsReceived', '_FinishedGoodsSold'];
  return state.product && shown.includes(state.actionType);
};

const AddQrCodeButton = ({ state, setState }) => {
  if (state.actionType !== '_LabelsProduced') {
    return <div/>;
  }

  return <Button onClick={() => setState({ currentPage: ScanThngPage })}>Add QR Code</Button>;
};

const ConfirmPage = ({ state, setState, appCallbacks }) => {
  const { onEventConfirmed, cleanEventData } = appCallbacks;
  const placeLabel = convertPlacesForSelect(state.places).find(p => p.value === state.place).label;
  const actionTypeLabel = ActionTypes.find(p => p.value === state.actionType).label;

  let descriptorStr = '';
  if (showSKU(state)) {
    descriptorStr = state.product.name;
    if (state.product.brand) {
      descriptorStr += ` (${state.product.brand})`;
    }
  }

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/clipboard-outline.png"/>
        <Title>Confirm Event Details</Title>
        <Paragraph>Check the details are correct, then confirm the event to create it.</Paragraph>

        <Subtitle>1. Scan Location</Subtitle>
        <Paragraph italics="true">{placeLabel}</Paragraph>

        <Subtitle>2. Event Type</Subtitle>
        <Paragraph italics="true">{actionTypeLabel}</Paragraph>

        <Subtitle>3. Event Data</Subtitle>
        <CustomFieldsTable state={state} data={state.actionFields}/>

        {showSKU(state) === true && (
          <div>
            <Subtitle>4. Chosen Item SKU</Subtitle>
            <Paragraph italics="true">{descriptorStr}</Paragraph>
            <ProductImage product={state.product}/>
            <CustomFieldsTable state={state} data={state.product.customFields}/>
          </div>
        )}

        <AddQrCodeButton state={state} setState={setState}/>
        <Button onClick={onEventConfirmed}>Finish</Button>
        <Button restyle={whiteButtonStyle} 
          onClick={() => {
            cleanEventData();
            setState({ currentPage: DataEntryPage });
          }}>
          Back
        </Button>
      </WideContainer>
    </Fader>
  );
};

export default ConfirmPage;
