import React from 'react';
import { whiteButtonStyle } from '../../../consumer-web-app/src/util';
import { FormSpacer, CollectionSelect } from '../components/FormComponents';
import Button from '../../../consumer-web-app/src/components/Button';
import Fader from '../../../consumer-web-app/src/components/Fader';
import DataEntryPage from './DataEntryPage';
import InputField from '../components/InputField';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

const PONumberPage = ({ state, setState, setStateSync, appCallbacks }) => {
  const { onPoIdConfirmed, onCollectionChosen } = appCallbacks;
  const { actionType, collection, collections } = state;

  const creatingResource = actionType === 'commissions';
  const paragraph = creatingResource
    ? 'Enter the Customer Purchase Order number to be used in creating the event.'
    : 'Choose an existing PO number to be used in creating the event.';

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/identify.png"/>
        <Title>PO Number Entry</Title>
        <Paragraph>{paragraph}</Paragraph>

        <FormSpacer/>
        {creatingResource === true && (
          <InputField type="text" placeholder="PO Number" value={state.enteredPoId}
            onChange={el => setState({ enteredPoId: el.target.value })}/>
        )}
        {creatingResource === false && (
          <CollectionSelect state={state} identifier="purchaseOrder" value={collection}
            onChange={(el) => {
              onCollectionChosen(el.target.value, 'enteredPoId', 'purchaseOrder')
                .then(() => onPoIdConfirmed());
            }}/>
        )}
        
        {creatingResource === true && <Button onClick={onPoIdConfirmed}>Confirm</Button>}
        <Button restyle={whiteButtonStyle} onClick={() => setState({ currentPage: DataEntryPage })}>
          Back
        </Button>
      </WideContainer>
    </Fader>
  );
};

export default PONumberPage;
