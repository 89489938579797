import React from 'react';
import { whiteButtonStyle } from '../../../consumer-web-app/src/util';
import { FormSpacer, CollectionSelect } from '../components/FormComponents';
import Button from '../../../consumer-web-app/src/components/Button';
import Fader from '../../../consumer-web-app/src/components/Fader';
import DataEntryPage from './DataEntryPage';
import InputField from '../components/InputField';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

const BatchNumberPage = ({ state, setState, setStateSync, appCallbacks }) => {
  const { onBatchIdConfirmed, onCollectionChosen } = appCallbacks;
  const { actionType, collection, collections } = state;

  const creatingResource = actionType === '_RawMaterialsProduced';
  const paragraph = creatingResource
    ? 'Enter the batch number of the raw material to be used in creating the event.'
    : 'Choose an existing batch number for the raw material to be used in creating the event.';

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/identify.png"/>
        <Title>Batch Number Entry</Title>
        <Paragraph>{paragraph}</Paragraph>

        <FormSpacer/>
        {creatingResource === true && (
          <InputField type="text" placeholder="Batch Number"
            onChange={el => setState({ enteredBatchId: el.target.value })}/>
        )}
        {creatingResource === false && (
          <CollectionSelect state={state} identifier="tapeBatchNumber" value={collection}
            onChange={(el) => {
              onCollectionChosen(el.target.value, 'enteredBatchId', 'tapeBatchNumber')
                .then(() => onBatchIdConfirmed());
            }}/>
        )}
        
        {creatingResource === true && <Button onClick={onBatchIdConfirmed}>Confirm</Button>}
        <Button restyle={whiteButtonStyle} onClick={() => setState({ currentPage: DataEntryPage })}>
          Back
        </Button>
      </WideContainer>
    </Fader>
  );
};

export default BatchNumberPage;
