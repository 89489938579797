import React from 'react';
import jsQR from 'jsqr';

const SCANTHNG_CONTAINER_ID = 'scanthng-container';
const ALLOWED_SYMBOLOGIES = ['ean_13', 'itf'];

class ScanProductPage extends React.Component {
  
  /**
   * When the component is mounted.
   */
  componentDidMount() {
    const { onEan13Available, onScanFail } = this.props.appCallbacks;

    // Use npm version of jsQR
    window.jsQR = jsQR;

    // Begin scanning
    EVT.use(EVT.Scan);
    this.props.state.appScope.scanStream({
      filter: { method: '1d', type: 'ean_13' },
      containerId: SCANTHNG_CONTAINER_ID,
    }).then((res) => {
        if (!res.length) {
          return Promise.reject('No item found!');
        }

        const found = res.find(p => ALLOWED_SYMBOLOGIES.includes(p.meta.type));
        if (!found) {
          return Promise.reject('No EAN13 or ITF barcode found');
        }

        const { value } = found.meta;
        if (value.length < 13 || value.length > 14) {
          return Promise.reject('Decoded code was not 13 or 14 characters in length (EAN13 or ITF)');
        }

        return onEan13Available(value);
      })
      .catch((err) => {
        alert(err);
        console.log(err);
        
        onScanFail();
      });
  }

  /**
   * Component render function.
   */
  render() {
    const style = {
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    };

    return <div id={SCANTHNG_CONTAINER_ID} style={style}></div>;
  }

}

export default ScanProductPage;
