import React from 'react';
import { Colors, Fonts } from '../../../consumer-web-app/src/util';
import { getFieldUnits } from '../util';
import ScanProductPage from '../pages/ScanProductPage';

export const DATA_ROW_CLASS = 'event-data-row';
export const FIELD_WIDTH = '160px';

export const FormSpacer = () => <div style={{ height: '10px' }}/>;

const DatePicker = ({ value, onChange }) => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    textAlign: 'left',
    width: FIELD_WIDTH,
  };

  return (
    <input type="date" value={value} className={DATA_ROW_CLASS} style={style} onChange={onChange}/>
  );
};

const SelectOption = ({ label, value }) => <option key={value} value={value}>{label}</option>;

export const Select = ({ items, value, onChange, width = '200px', horizMargin = 'auto' }) => {
  const style = {
    display: 'block',
    border: `1px solid ${Colors.lightGrey}`,
    backgroundColor: 'white',
    height: '30px',
    minWidth: width,
    margin: `5px ${horizMargin}`,
    fontSize: '1rem',
  };

  return (
    <select style={style} className={DATA_ROW_CLASS} value={value} onChange={onChange}>
      {items.map(SelectOption)}
    </select>
  );
};

const MaterialSelect = ({ value, onChange }) => {
  const options = [
    { label: 'Select a material', value: '' },
    { label: 'Tape', value: 'tape' },
    { label: 'Ink', value: 'ink' },
  ];

  return (
    <Select width={FIELD_WIDTH} items={options} value={value}
      onChange={onChange} horizMargin="0px" />
  );
};

/* Shipping from Italy to... */
const DestinationSelect = ({ value, onChange }) => {
  const options = [
    { label: 'Select a destination...', value: '' },
    { label: 'Avery Dennison Spain', value: 'Avery Dennison Spain', },
    { label: 'Avery Dennison NTP AS', value: 'Avery Dennison NTP AS', },
    { label: 'Customer', value: 'Customer', },
  ];

  return (
    <Select width={FIELD_WIDTH} items={options} value={value} onChange={onChange}
      horizMargin="0px"/>
  );
};

const ScanProductButton = ({ setState }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: Colors.white,
    backgroundColor: Colors.black,
    height: '35px',
    width: '160px',
    fontFamily: Fonts.body,
    fontSize: '0.9rem',
    textAlign: 'center',
    cursor: 'pointer',
  };

  return (
    <div style={style} onClick={() => setState({ currentPage: ScanProductPage})}>
      Scan EAN13 Barcode
    </div>
  );
};

const ProductSelect = ({ state, setState, onChange }) => {
  const initial = [{ label: 'Select a product...', value: '' }];
  const productOptions = state.products.map(p => ({ label: p.name, value: p.id }));
  const options = initial.concat(productOptions);

  return (
    <div>
      <Select width={FIELD_WIDTH} items={options} value={state.actionFields.product}
        onChange={onChange} horizMargin="0px"/>
      <ScanProductButton setState={setState}/>
    </div>
  );
};

export const CollectionSelect = ({ state, identifier, value, onChange, horizMargin = 'auto' }) => {
  const initial = [{ label: 'Select an item...', value: '' }];
  const collectionOptions = state.collections
    .filter(p => p.identifiers[identifier])
    .map(p => ({ label: p.name.split(' ').slice(2), value: p.id }));
  const options = initial.concat(collectionOptions);

  return (
    <Select width={FIELD_WIDTH} items={options} value={value} horizMargin={horizMargin}
      onChange={onChange}/>
  );
};

const DataInput = ({ fieldName, value, onChange }) => {
  const style = {
    width: FIELD_WIDTH,
    textAlign: 'left',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    height: '30px',
    margin: '10px auto',
    paddingLeft: '5px',
    border: `1px solid ${Colors.lightGrey}`,
  };

  return (
    <input type="text" className={DATA_ROW_CLASS} style={style} value={value} onChange={onChange}/>
  );
};

const UnitsView = ({ fieldName }) => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '0.9rem',
    color: Colors.darkGrey,
    marginLeft: '5px',
  };

  return <span style={style}>{getFieldUnits(fieldName)}</span>;
};

/**
 * Get the right input component for the field name.
 *
 * @param {object} state - App state.
 * @param {function} setState - App setState function.
 * @param {object} appCallbacks - App callbacks.
 * @param {string} fieldName - Name of the field.
 * @param {function} updateField - 'el' callback for element onchange.
 * @returns {HTMLElement} The element to use as input.
 */
export const getInputForm = (state, setState, appCallbacks, fieldName, updateField) => {
  const { onCollectionChosen } = appCallbacks;

  // tapeBatchNumber in _LabelsProduced is always selected
  if (fieldName === 'tapeBatchNumber') {
    const chosenCollection = state.collections
      .find(p => p.identifiers.tapeBatchNumber === state.enteredBatchId);
    const collectionSelectValue = chosenCollection ? chosenCollection.id : '';
    return (
      <CollectionSelect state={state} identifier="tapeBatchNumber" horizMargin="0px"
        value={collectionSelectValue}
        onChange={(el) => {
          onCollectionChosen(el.target.value, 'enteredBatchId', 'tapeBatchNumber');
        }}/>
    );
  }

  // (Customer PO Number is never selected, since the collection is created in Label Applied)
  //
  // // purchaseOrder is entered in _LabelsProduced, but is chosen from those 
  // // available in commissions (Label Applied)
  // if (fieldName === 'Customer PO Number' && state.actionType === 'commissions') {
  //   const chosenCollection = state.collections
  //     .find(p => p.identifiers.purchaseOrder === state.enteredPoId);
  //   const collectionSelectValue = chosenCollection ? chosenCollection.id : '';
  //   return (
  //     <CollectionSelect state={state} identifier="purchaseOrder" horizMargin="0px"
  //       value={collectionSelectValue}
  //       onChange={(el) => {
  //         onCollectionChosen(el.target.value, 'enteredPoId', 'purchaseOrder');
  //       }}/>
  //   );
  // }

  // Special type
  const map = {
    productionDate: DatePicker,
    productionDates: DatePicker,
    receivingDate: DatePicker,
    shippingDate: DatePicker,
    materialType: MaterialSelect,
    destination: DestinationSelect,
    product: ProductSelect,
  };
  if (map[fieldName]) {
    const Component = map[fieldName];
    return (
      <Component state={state} setState={setState}
        onChange={updateField}
        value={state.actionFields[fieldName] || ''}/>
    );
  }

  // Standard text field (including Customer PO Number initial entry)
  return (
    <div>
      <DataInput fieldName={fieldName} value={state.actionFields[fieldName] || ''} 
        onChange={updateField}/>
      <UnitsView fieldName={fieldName}/>
    </div>
  );
};
