import React from 'react';
import { Colors, Fonts } from '../../../consumer-web-app/src/util';

const Container = ({ children }) => {
  const style = { margin: '15px' };

  return <div style={style}>{children}</div>;
};

const RowContainer = ({ children }) => {
  const style = {
    alignItems: 'center',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
  };

  return <div style={style}>{children}</div>;
};

const Label = ({ children }) => {
  const style = {
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    maxWidth: '90%',
    textAlign: 'center',
  };

  return <p style={style}>{children}</p>;
};

const SpinnerImage = ({ state }) => {
  const style = {
    width: '32px',
    height: 'auto',
    marginRight: '5px',
  };

  const src = (state.actionType === 'commissions')
    ? '../../assets/timer.png'
    : (state.eventInProgress === true)
      ? '../../assets/spinner.gif' 
      : '../../assets/check.png';
  return <img style={style} src={src}/>;
};

const IOTAProgressView = ({ state }) => {
  const status = (state.actionType === 'commissions')
    ? 'Wait approx. 1 minute before continuing.'
    : (state.eventInProgress === true)
      ? 'Awaiting confirmation...' 
      : 'Confirmation complete!';

  return (
    <Container>
      <RowContainer>
        <SpinnerImage state={state}/>
        <Label>{status}</Label>
      </RowContainer>
    </Container>
  );
};

export default IOTAProgressView;
