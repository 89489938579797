import React from 'react';
import { FormSpacer } from '../components/FormComponents';
import Button from '../../../consumer-web-app/src/components/Button';
import Fader from '../../../consumer-web-app/src/components/Fader';
import InputField from '../components/InputField';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  /**
   * What the component will render.
   */
  render() {
    const { onLoginUser } = this.props.appCallbacks;

    return (
      <Fader>
        <WideContainer>
          <PageIcon src="../../assets/login.png"/>
          <Title>Traceability Login</Title>
          <Paragraph>Login to begin recording traceability events.</Paragraph>

          <FormSpacer/>
          <InputField type="email" placeholder="Email Address" value={this.state.email}
            onChange={el => this.setState({ email: el.target.value })}/>
          <InputField type="password" placeholder="Password" value={this.state.password}
            onChange={el => this.setState({ password: el.target.value })}/>

          <Button onClick={() => onLoginUser(this.state.email, this.state.password)}>
            Login
          </Button>
        </WideContainer>
      </Fader>
    );
  }

}

export default LoginPage;
