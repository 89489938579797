import React from 'react';
import ReactDOM from 'react-dom';
import { LS_USER_KEY, LS_USER_ID, ActionTypes, ActionIdentifyMethods, runTaskBatch } from './util';
import { Colors, Fonts } from '../../consumer-web-app/src/util';
import { NAVBAR_HEIGHT, NavBar, NavBarSpacer } from '../../consumer-web-app/src/components/NavBar';
import {
  loadProducts, loadPlaces, loadCollections, findThngByShortId, requestThngsCreated, 
  createEncodingsAction, addThngToCollection, logOut, commissionThng, getPOCollection, 
  getBatchCollection, checkEventExists, manageEventInProgress,
} from './platform';
import CompletePage from './pages/CompletePage';
import ConfirmPage from './pages/ConfirmPage';
import DataEntryPage from './pages/DataEntryPage';
import Fader from '../../consumer-web-app/src/components/Fader';
import LoadingPage from './pages/LoadingPage';
import LoginPage from './pages/LoginPage';

const IOTA_CHECK_INTERVAL_MS = 5000;

const RootContainer = ({ children }) => {
  const style = { display: 'flex', flexDirection: 'column' };

  return <div style={style}>{children}</div>;
};

const UserView = ({ scope }) => {
  const style = {
    backgroundColor: Colors.lightGreen,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: Fonts.body,
    fontSize: '1.0rem',
    height: '35px',
    justifyContent: 'center',
    marginTop: NAVBAR_HEIGHT,
    position: 'fixed',
    textAlign: 'center',
    width: '100%',
    boxShadow: '0px 2px 3px 1px #0004',
  };

  return <div style={style}>{scope.email}</div>;
};

class Application extends React.Component {

  /**
   * @constructor
   *
   * @param {object} props - The properties passed from the parent scope.
   */
  constructor(props) {
    super(props);

    this.state = {
      actionFields: {},
      actionType: ActionTypes[0].value,
      appScope: null,
      appUserScope: null,
      collection: '',
      currentPage: LoadingPage,
      enteredBatchId: '',
      enteredPoId: '',
      place: '',
      places: [],
      product: null,
      products: [],
      collections: [],
      scannedThngs: [],
      missingSerials: [],
      eventInProgress: false,
      lastActionCreated: null,
    };

    this.setState = this.setState.bind(this);
    this.setStateSync = this.setStateSync.bind(this);

    this.cleanEventData = this.cleanEventData.bind(this);
    this.onLoginUser = this.onLoginUser.bind(this);
    this.onBatchIdConfirmed = this.onBatchIdConfirmed.bind(this);
    this.onEan13Available = this.onEan13Available.bind(this);
    this.onEventConfirmed = this.onEventConfirmed.bind(this);
    this.onPoIdConfirmed = this.onPoIdConfirmed.bind(this);
    this.onScanFail = this.onScanFail.bind(this);
    this.onShortIdNoThng = this.onShortIdNoThng.bind(this);
    this.onThngScanned = this.onThngScanned.bind(this);
    this.onCollectionChosen = this.onCollectionChosen.bind(this);
  }

  /**
   * When the component is mounted.
   */
  componentDidMount() {
    // Disable device geolocation so action.location.place can be set
    EVT.setup({ geolocation: false });

    const appScope = new EVT.App(window.config.APPLICATION_API_KEY);
    this.setState({ appScope });

    // User already logged in or not?
    if (!localStorage.getItem(LS_USER_KEY)) {
      this.setState({ currentPage: LoginPage });
      return;
    }

    const appUserScope = new EVT.User({
      id: localStorage.getItem(LS_USER_ID),
      apiKey: localStorage.getItem(LS_USER_KEY),
    });
    appUserScope.$init
      .then(() => this.setStateSync({ appUserScope }))
      .then(this.onUserAvailable.bind(this))
      .catch(err => logOut(this.state, this.setState));
  }

  /**
   * Set state in a Promise, so that the changes are committed in the next in
   * the chain.
   *
   * @param {object} diff - The changes.
   * @returns {Promise} Promise that resolves once the changes are committed.
   */
  setStateSync(diff) {
    return new Promise(resolve => this.setState(diff, resolve));
  }

  /**
   * When the Scanner component has scanned a QR code, or when batch entry is submitted.
   *
   * @param {string} thngId - The Thng ID result of the scan request, or entered by the user.
   */
  onThngScanned(thngId) {
    return this.setStateSync({ currentPage: LoadingPage })
      .then(() => this.state.appUserScope.thng(thngId).read())
      .then((thng) => {
        const { scannedThngs } = this.state;
        if (!scannedThngs.find(p => p.id === thngId)) {
          // If the Thng hasn't already been scanned, add it to the list
          scannedThngs.push(thng);
        }

        return this.setStateSync({ scannedThngs, currentPage: ConfirmPage });
      });
  }

  /**
   * When the ScanProductPage has obtained a ScanThng result containing ean_13 data.
   *
   * @param {string} ean13 - The ean_13 result of the scan request.
   */
  onEan13Available(ean13) {
    // Trim the check digit
    // ean13 = ean13.substring(0, 13);

    // Pad to 14 with zeros
    while (ean13.length < 14) {
      ean13 = `0${ean13}`;
    }

    const { actionFields } = this.state;
    actionFields.product = this.state.products.find(p => p.identifiers.ean_13 === ean13).id;
    this.setState({ actionFields, currentPage: DataEntryPage });
  }

  /**
   * Reset in actionFields to only contain user-entered data
   */
  cleanEventData() {
    const { actionFields } = this.state;
    delete actionFields.sendToIOTA;
    delete actionFields.createOriginTrail;
    delete actionFields.replicateOriginTrail;
    delete actionFields.firstName;

    this.setState({
      actionFields,
      scannedThngs: [],
      missingSerials: [],
      lastActionCreated: null,
    });
  }

  /**
   * Single function to create an event, either on a Thng or collection.
   *
   * @param {string} id - The resource ID.
   * @param {string} targetType - The type of resource.
   * @returns {Promise} Promise that resolves when done.
   */
  createEventAction(id, targetType, inProgressChecked) {
    const { actionType, place, actionFields, appUserScope } = this.state;
    const payload = {
      type: actionType,
      [targetType]: id,
      locationSource: 'place',
      location: { place },
      customFields: actionFields,
    };

    // Event data plus the following
    payload.customFields.firstName = appUserScope.firstName;
    payload.customFields.sendToIOTA = true;
    payload.customFields.createOriginTrail = true;
    payload.customFields.replicateOriginTrail = true;

    return checkEventExists(this.state, this.setState, id, targetType)
      .then(() => {
        // From createEventsForScannedThngs to createEvent in the case of Thng scanned
        // It is possible that manageEventInProgress is called twice - setting and tripping
        // the eventInProgress flag within a second.
        // FIXME - Fix this workflow to avoid this problem.
        if (!inProgressChecked) {
          return manageEventInProgress(this.state, this.setStateSync, id, targetType);
        }

        return Promise.resolve();
      })
      .then(() => appUserScope[targetType](id).action(actionType).create(payload))
      .then(res => this.setStateSync({ lastActionCreated: res }));
  }

  /**
   * Create event action with the current app state.
   *
   * @param {object} thng - The Thng to use.
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  createEvent(thng, inProgressChecked) {
    const targetMap = {
      scan: () => this.createEventAction(thng.id, 'thng', inProgressChecked),
      batch: () => getBatchCollection(this.state).then(id => this.createEventAction(id, 'collection')),
      po: () => getPOCollection(this.state).then(id => this.createEventAction(id, 'collection')),
    };

    return targetMap[ActionIdentifyMethods[this.state.actionType]]();
  }

  /**
   * Create a single event action, handling special action type cases.
   *
   * @param {object} thng - The Thng to use.
   * @returns {Promise} Promise that resolves when complete.
   */
  createSingleEvent(thng, inProgressChecked) {
    // Put Thng in PO collection and commission
    if (this.state.actionType === 'commissions') {
      return createEncodingsAction(this.state, thng)
        .then(() => getPOCollection(this.state))
        .then(id => addThngToCollection(this.state, id, thng))
        .then(() => commissionThng(this.state, thng));
    }

    // Put Thng in batch collection, then proceed to event creation
    if (this.state.actionType === '_LabelsProduced') {
      return getBatchCollection(this.state)
        .then(id => addThngToCollection(this.state, id, thng))
        .then(() => this.createEvent(thng, inProgressChecked));
    }

    return this.createEvent(thng, inProgressChecked);
  }

  /**
   * Poll every 5 seconds for a Thng _sentToIOTA action with an originalAction id
   * the same as one just created.
   *
   * @returns {Promise} Promise that resolves immediately - the UI updates when IOTA is done.
   */
  beginWatchingIOTA() {
    if (this.state.actionType === 'commissions') {
      // Skip this, we can't easily confirm it due to needing secondard _Commissioned action
      return Promise.resolve();
    }

    const handle = setInterval(() => {
      // Read Thng _sentToIOTA actions
      this.state.appUserScope.action('_sentToIOTA')
        .read()
        .then((res) => {
          if (!res.length) {
            return;
          }

          try {
            // Check originalAction ID matches that of the action awaiting confirmation
            const confirmation = res.find(p => p.customFields.originalAction.id === this.state.lastActionCreated.id);
            if (!confirmation) {
              return;
            }

            clearInterval(handle);
            this.setState({ eventInProgress: false });
          } catch (e) {
            // lastActionCreated no longer valid, or user continued prematurely
            clearInterval(handle);
            this.setState({ eventInProgress: false });
          }
        });
    }, IOTA_CHECK_INTERVAL_MS);
    return Promise.resolve();
  }

  /**
   * Handle completion and errors from multiple event creation pathways.
   * - Watch for IOTA confirmation.
   * - Reload collections since a new one could have been created and needed before
   *   the app is reloaded.
   *
   * @returns {Promise} Promise that resolves when state is set without error.
   */
  onEventCreated() {
    return this.beginWatchingIOTA()
      .then(() => loadCollections(this.state, this.setState))
      .then(() => this.setStateSync({ currentPage: CompletePage }))
      .catch((err) => {
        console.log(err);
        alert(err.message || err.errors[0]);
        this.setState({ currentPage: DataEntryPage });
      });
  }

  /**
   * Create an event identical for each Thng in scanned Thngs in an orderly fashion.
   *
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  createEventsForScannedThngs() {
    const tasks = this.state.scannedThngs.map(p => () => {
      return checkEventExists(this.state, this.setState, p.id, 'thng')
        .then(() => manageEventInProgress(this.state, this.setStateSync, p.id, 'thng'))
        .then(() => this.createSingleEvent(p, true));
    });

    return runTaskBatch(tasks)
      .then(() => this.onEventCreated())
      .catch((e) => {
        console.log(e);
        alert(e.message || e.errors[0]);
      });
  }

  /**
   * When the event details have been confirmed.
   * Two possible routes - Thngs that exist or some that don't.
   */
  onEventConfirmed() {
    const { missingSerials, scannedThngs } = this.state;

    this.setState({ currentPage: LoadingPage });

    // Not an event on a Thng, and no missing serials - event is on a collection
    if (!scannedThngs.length && !missingSerials.length) {
      return this.createEvent()
        .then(() => this.onEventCreated());
    }

    // Some serials need to be created, as scanned Thngs did not exist by serial number.
    if (missingSerials.length) {
      return requestThngsCreated(this.state)
        .then((newThngs) => {
          // Add the new Thngs to state.scannedThngs
          const { scannedThngs } = this.state;
          return this.setStateSync({ scannedThngs: scannedThngs.concat(newThngs) });
        })
        .then(() => this.createEventsForScannedThngs());
    }

    // Event created on Thngs, but they all exist already.
    return this.createEventsForScannedThngs();
  }

  /**
   * After a batch/PO number has been manually entered and the collection found.
   *
   * @param {string} collectionId - The collection found or created.
   * @returns {Promise} Promise that resolves when complete.
   */
  onCollectionIdEntered(collectionId) {
    return this.state.appUserScope.collection(collectionId).read()
      .then(collection => this.setStateSync({ collection }))
      .then(() => this.setState({ currentPage: ConfirmPage }));
  }

  /**
   * When the user has logged in, or was loaded from localstorage.
   *
   * @returns {Promise} Promise that resolves when complete.
   */
  onUserAvailable() {
    return loadPlaces(this.state, this.setState)
      .then(() => loadProducts(this.state, this.setState))
      .then(() => loadCollections(this.state, this.setState))
      .then(() => this.setState({ currentPage: DataEntryPage }));
  }

  /**
   * Log in an App User with the user-provided credentials.
   */
  onLoginUser(email, password) {
    this.setStateSync({ currentPage: LoadingPage })
      .then(() => this.state.appScope.login({ email, password }))
      .then(({ user }) => {
        localStorage.setItem(LS_USER_ID, user.id);
        localStorage.setItem(LS_USER_KEY, user.apiKey);
        return this.setStateSync({ appUserScope: user });
      })
      .then(() => this.onUserAvailable())
      .catch((err) => {
        console.log(err);
        alert('Login failed! Check the email and password you entered are correct.');

        this.setState({
          currentPage: LoginPage,
          email: '',
          password: '',
        });
      });
  }

  /**
   * When a scan fails to resolve a recognised Thng
   */
  onScanFail() {
    this.setState({ currentPage: DataEntryPage });
  }

  /*
   * Item ID was manually entered and confirmed, should be a PO number
   * that links to a collection.
   */
  onPoIdConfirmed() {
    // Also add to the event data
    const { actionFields, enteredPoId } = this.state;
    actionFields['Customer PO Number'] = enteredPoId;

    return this.setStateSync({ actionFields, currentPage: LoadingPage })
      .then(() => getPOCollection(this.state))
      .then(id => this.onCollectionIdEntered(id));
  }

  /*
   * Item ID was manually entered and confirmed, should be a batch number
   * that links to a collection.
   */
  onBatchIdConfirmed() {
    // Also add to the event data
    const { actionFields, enteredBatchId } = this.state;
    actionFields.tapeBatchNumber = enteredBatchId;

    return this.setStateSync({ actionFields, currentPage: LoadingPage })
      .then(() => getBatchCollection(this.state))
      .then(id => this.onCollectionIdEntered(id));
  }

  /**
   * A collection was chosen from the dropdown, as opposed to being entered as text.
   *
   * Note: This must not navigate - used in EventDataTable > getInputForm()
   *
   * @param {string} id - The collection ID.
   * @param {string} stateKey - The key in `state` to update.
   * @param {string} identifierKey - The key in `identifiers` to read.
   */
  onCollectionChosen(id, stateKey, identifierKey) {
    const collection = this.state.collections.find(p => p.id === id);
    return this.setStateSync({ [stateKey]: collection.identifiers[identifierKey] });
  }

  /**
   * ScanThng decoded the QR code, but no Thng was found -> a Thng needs to be created.
   *
   * @param {string} value - Raw string decoded from scanned image.
   */
  onShortIdNoThng(value) {
    const { missingSerials } = this.state;
    const serial = value.split('/')[3];
    if (!missingSerials.includes(serial)) {
      missingSerials.push(serial);
    }

    // Remember the serial, then go to confirmation page incase another scan is required.
    return this.setStateSync({ missingSerials })
      .then(() => this.setState({ currentPage: ConfirmPage }));
  }

  /**
   * What the component will render.
   */
  render() {
    const CurrentPage = this.state.currentPage;
    const appCallbacks = {
      cleanEventData: this.cleanEventData,
      onLoginUser: this.onLoginUser,
      onBatchIdConfirmed: this.onBatchIdConfirmed,
      onEan13Available: this.onEan13Available,
      onEventConfirmed: this.onEventConfirmed,
      onPoIdConfirmed: this.onPoIdConfirmed,
      onScanFail: this.onScanFail,
      onShortIdNoThng: this.onShortIdNoThng,
      onThngScanned: this.onThngScanned,
      onCollectionChosen: this.onCollectionChosen,
    };

    return (
      <RootContainer>
        {this.state.appUserScope !== null && (
          <Fader>
            <UserView scope={this.state.appUserScope}/>
          </Fader>
        )}
        <NavBar logoSrc="../assets/logo-white.png" showBack={this.state.appUserScope !== null}
          backSrc="../assets/logout.png" backOnClick={() => logOut(this.state, this.setState)}/>
        <NavBarSpacer/>
        <CurrentPage state={this.state} setState={this.setState.bind(this)}
          setStateSync={this.setStateSync.bind(this)}
          appCallbacks={appCallbacks}/>
      </RootContainer>
    );
  }

}

ReactDOM.render(<Application/>, document.getElementById('app'));
