import React from 'react';
import Button from '../../../consumer-web-app/src/components/Button';
import Fader from '../../../consumer-web-app/src/components/Fader';
import DataEntryPage from './DataEntryPage';
import IOTAProgressView from '../components/IOTAProgressView';
import PageIcon from '../../../consumer-web-app/src/components/PageIcon';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';
import Title from '../../../consumer-web-app/src/components/Title';
import WideContainer from '../../../consumer-web-app/src/components/WideContainer';

const CompletePage = ({ state, setState, appCallbacks }) => {
  const { cleanEventData } = appCallbacks;

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/complete.png"/>
        <Title>Event Created</Title>
        <Paragraph>The event was created successfully.</Paragraph>
        <Paragraph>Please wait for the blockchain transaction to be confirmed.</Paragraph>
        <IOTAProgressView state={state}/>

        <Button onClick={() => {
          cleanEventData();
          setState({ currentPage: DataEntryPage });
        }}>
          Next Event
        </Button>
      </WideContainer>
    </Fader>
  );
};

export default CompletePage;
