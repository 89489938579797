import React from 'react';
import { Colors, Fonts } from '../../../consumer-web-app/src/util';

const InputField = ({ onChange, type = 'text', placeholder = '', width = '250px' }) => {
  const style = {
    width,
    textAlign: 'center',
    display: 'block',
    fontFamily: Fonts.body,
    fontSize: '1rem',
    height: '30px',
    margin: '10px auto',
    paddingLeft: '0px',
    border: `1px solid ${Colors.lightGrey}`,
  };
  
  return <input style={style} type={type} placeholder={placeholder} onChange={onChange}/>;
};

export default InputField;
