import React from 'react';
import { Fonts, formatCamelCase } from '../../../consumer-web-app/src/util';
import { ActionCustomFields } from '../util';
import { getInputForm, CollectionSelect } from './FormComponents';
import Paragraph from '../../../consumer-web-app/src/components/Paragraph';

const RowLabel = ({ children }) => {
  const style = {
    fontFamily: Fonts.body,
    fontSize: '1rem',
    textAlign: 'right',
    paddingRight: '5px',
    maxWidth: '120px',
  };

  return <div style={style}>{children}</div>;
};

const EventDataRow = (state, setState, appCallbacks, fieldName, updateField) => (
  <tr key={fieldName}>
    <td><RowLabel>{formatCamelCase(fieldName)}</RowLabel></td>
    <td style={{ width: '230px', textAlign: 'left' }}>
      {getInputForm(state, setState, appCallbacks, fieldName, updateField)}
    </td>
  </tr>
);

const EventDataTable = ({ state, setState, appCallbacks, actionType }) => {
  const fields = ActionCustomFields[actionType];
  if (!fields || !fields.length) {
    return <Paragraph>None required</Paragraph>;
  }

  const style = { margin: 'auto' };
  return (
    <table style={style}>
      <tbody>{fields.map((key) => {
        const updateField = el =>
          setState({ actionFields: Object.assign(state.actionFields, { [key]: el.target.value }) });
        return EventDataRow(state, setState, appCallbacks, key, updateField);
      })}</tbody>
    </table>
  );
};

export default EventDataTable;
